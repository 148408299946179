/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { forwardRef, memo, useCallback, useMemo, useRef } from "react";
import { CircularProgress } from "@mui/material";
import omit from "lodash/omit";
import moment from "moment-timezone";

import { LOADING_STYLE } from "@:lite/helpers/constants";
import {
  isStatusNotFinished,
  isStatusRequestedOrPlanned,
} from "@:lite/helpers/isStatus";
import {
  OrderStatusColors,
  OrderStatusEnum,
} from "@:lite/helpers/OrderStatusColors";
import { useDraggableItem } from "@:lite/hooks/ui/timeline/useDraggableItem";
import { useNeedToMakeItemSecondary } from "@:lite/hooks/ui/timeline/useNeedToMakeItemSecondary";
import { useOrderSize } from "@:lite/hooks/ui/timeline/useOrderSize";
import {
  DraggableItemProps,
  DraggableItemType,
  OnDraggableSideDropFn,
  VisualColorTheme,
  WorkOrder as WorkOrderTypes,
} from "@:lite/types";
import { useInProgressStore } from "@:lite/zustand/useInProgressStore";
import {
  DEFAULT_ALLOCATION_HEIGHT,
  setSidePanelOrder,
  useOrderStore,
} from "@:lite/zustand/useOrderStore";
import { useSettingsStore, useUserData } from "@:lite/zustand/useSettingsStore";

import OrderCardDraggableSides from "../draggableSide/orderCardDraggableSides";

import styles from "../index.module.scss";
import textStyles from "@:lite/style/textStyles.module.scss";

type DragWorkOrderProps = {
  id: string;
  workOrder: WorkOrderTypes;
  elementWidth: number;
  position: number;
  selectedDate: Date;
  visibleDays: number;
  timeLine: string[];
  onPullEnd: OnDraggableSideDropFn;
};

const draggableItemType = DraggableItemType.WorkOrder;

const WorkOrderAllocation = forwardRef<HTMLInputElement, DragWorkOrderProps>(
  (
    {
      workOrder,
      elementWidth,
      position,
      selectedDate,
      visibleDays,
      timeLine,
      onPullEnd,
    },
    ref,
  ) => {
    const sidePanelOrder = useOrderStore((state) => state.sidePanelOrder);
    const woChangeInProgress = useInProgressStore(
      (state) => state.woChangeInProgress,
    );
    const inDetailedView = useSettingsStore((state) => state.inDetailedView);

    const { visibleStartDate } = useSettingsStore(
      (state) => state.visibleDateRange,
    );

    const { userCompany, imoNumbers } = useUserData();
    let isOurItem = false;
    if (userCompany) {
      isOurItem = userCompany === workOrder.customerName;
    } else if (!userCompany && imoNumbers.length === 0) {
      isOurItem = true;
    }
    const isRequestedOrPlanned = isStatusRequestedOrPlanned(
      workOrder?.derivedStatus,
    );
    const isNotFinished = isStatusNotFinished(workOrder?.derivedStatus);

    const orderInfo = useRef<any>(null);
    const isLoading = woChangeInProgress.some(
      (each) => each.id === workOrder.id,
    );
    const draggableProps: DraggableItemProps = {
      canDrag: !isLoading,
      quayId: workOrder.normalizedQuay,
      dragScope: [workOrder.normalizedQuay],
    };

    const [{ isDragging }, drag] = useDraggableItem({
      type: draggableItemType,
      timeLine,
      orderId: workOrder.id,
      ...draggableProps,
      canDrag: isOurItem && !isLoading && isRequestedOrPlanned,
    });

    const getVisualColorTheme = useCallback(
      (status: string): VisualColorTheme => {
        return isLoading
          ? LOADING_STYLE
          : OrderStatusColors.getStatus(status as OrderStatusEnum);
      },
      [isLoading],
    );

    const needToMakeItemSecondary = useNeedToMakeItemSecondary({
      order: workOrder,
    });

    const { width } = useOrderSize({
      order: workOrder,
      selectedDate,
      elementWidth,
      visibleDays,
    });

    // @TODO: check can the implementation from the vessels card be reused
    const orderStyles = useMemo(() => {
      let finalWidth = Math.max(width, elementWidth * 3); // elementWidth*3, why 3 (3 hours)? Because it's min width of 3 hours we declared in intervalGraphColoring.

      const finalStartDate = workOrder.plannedStartDate;
      // Calculate the time difference in minutes
      let startDiff = -visibleStartDate.diff(finalStartDate, "minutes");

      // Detect if there's a DST boundary between `visibleStartDate` and `finalStartDate`
      const visibleIsDST = moment(visibleStartDate).isDST();
      const startIsDST = moment(finalStartDate).isDST();

      // Adjust startDiff by 60 minutes if a DST boundary was crossed
      if (visibleIsDST !== startIsDST) {
        startDiff -= 60; // Subtract 1 hour if crossing into DST
      }

      // Convert minutes to pixels for the `left` position
      const left = (startDiff > 0 ? startDiff : 0) * (elementWidth / 60);
      const timelineContainerWidth = (elementWidth / 2) * 48 * 2; // TODO: don't hardcode
      if (left + finalWidth > timelineContainerWidth) {
        finalWidth = timelineContainerWidth - left; // To prevent DetailedView allocations to overflow the right edge of the timeline
      }
      return {
        borderColor: getVisualColorTheme(workOrder.derivedStatus).color,
        width: finalWidth || 0,
        borderWidth: sidePanelOrder?.id === workOrder.id ? "4px" : "2px",
        boxShadow:
          sidePanelOrder?.id === workOrder.id
            ? "0px 8px 10px rgba(90, 90, 90, 0.14), 0px 3px 14px rgba(90, 90, 90, 0.12), 0px 4px 5px rgba(90, 90, 90, 0.2)"
            : "none",
        height: workOrder?.allocHeight,
        top: workOrder?.visualTop,
        left,
        pointerEvents:
          isDragging || needToMakeItemSecondary
            ? "none"
            : ("inherit" as React.CSSProperties["pointerEvents"]),
        color: isLoading ? "#C4C4C4" : "inherit",
      };
    }, [
      width,
      elementWidth,
      visibleStartDate,
      workOrder.plannedStartDate,
      workOrder.derivedStatus,
      workOrder.id,
      workOrder?.allocHeight,
      workOrder?.visualTop,
      getVisualColorTheme,
      sidePanelOrder?.id,
      isDragging,
      needToMakeItemSecondary,
      isLoading,
    ]);

    const renderEventInfo = (order: WorkOrderTypes) => {
      const textStyle = {
        overflow: `${inDetailedView ? "visible" : "hidden"}`,
        fontWeight: 700,
        color: isLoading ? "#C4C4C4" : "inherit",
      };

      const subtitleStyle = {
        overflow: `${inDetailedView ? "visible" : "hidden"}`,
        color: isLoading ? "#C4C4C4" : "inherit",
        marginTop: "8px",
      };

      const orderNumberSuffix = `#${order.orderNumber.substring(
        order.orderNumber.length - 5,
      )}`;

      const specifyDescription = order.workOrderNotes.find(
        (note) => note.description === "Requirements and quantities",
      )?.specifyDescription;

      const orderInfo = specifyDescription
        ? `${orderNumberSuffix} - ${specifyDescription}`
        : orderNumberSuffix;

      const JSX = (
        <div
          key={order.id}
          className={styles.workOrderBlock}
          style={{ display: "block" }}
        >
          <div className={styles.eventInfo}>
            <span style={textStyle} className={textStyles.primaryText}>
              {`${order.description} - ${order.customerDisplayName}`}
            </span>
          </div>

          <div className={styles.eventInfoBlock}>
            <div
              key={order.id}
              className={styles.workOrderBlock}
              style={{ display: "flex" }}
            >
              <div className={styles.eventInfo}>
                <span
                  style={subtitleStyle}
                  className={textStyles.secondaryText}
                >
                  {orderInfo}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
      return JSX;
    };

    if (!sidePanelOrder?.id) {
      orderStyles.boxShadow = "none";
    }

    // @TODO: check can the implementation from the vessels card be reused
    const wrapperStyles = useMemo(() => {
      const widthCSS = "fit-content";
      const flexDirection = "column";
      const alignItems = "";
      return {
        width: widthCSS,
        flexDirection,
        alignItems,
        display: "flex",
      };
    }, []);

    const orderContentWrapperStyles = {
      backgroundColor: isDragging
        ? "transparent"
        : getVisualColorTheme(workOrder.derivedStatus).background,
      width: Math.max(width - 3, 0),
    };

    const onAllocationClick = (e) => {
      if (isLoading) {
        return;
      }
      // Handle edge case. "onAllocationClick" gets triggered also when clicking away from the 3-dots menu, or click modal buttons
      if (
        e.target?.classList?.contains("popup-overlay") ||
        e.target?.classList?.value?.includes("modalButton")
      ) {
        return;
      }

      // setTimeout fixes the race condition in the click event handling that
      // It allows the previous active sidepanel to have time detect clickaway and close itself + saving data before opening this one
      setTimeout(() => {
        setSidePanelOrder({ id: workOrder?.id });
      }, 5);
    };

    return (
      <>
        <div
          className="floatingLabel"
          ref={ref}
          data-allocation-width={orderStyles.width - 30}
          data-allocation-offsetleft={orderStyles.left}
          style={{
            ...orderContentWrapperStyles,
            ...{
              position: "absolute",
              top:
                workOrder?.allocHeight > DEFAULT_ALLOCATION_HEIGHT / 2
                  ? orderStyles.top + 12
                  : orderStyles.top + 8,
              left: orderStyles.left + 20,
              backgroundColor: "transparent",
              zIndex: 2,
              width: Math.max(orderStyles.width - 60, 20),
              overflow: "hidden",
            },
          }}
        >
          <div ref={orderInfo} style={wrapperStyles as React.CSSProperties}>
            {renderEventInfo(workOrder)}
          </div>
        </div>
        <div
          className={styles.tableOrderContainer}
          style={{
            marginLeft: position,
            top: orderStyles.top,
            height: orderStyles.height,
            left: orderStyles.left,
          }}
          tabIndex={0}
          role="button"
          onClick={onAllocationClick}
          onKeyDown={() => null}
        >
          {isOurItem && (
            <OrderCardDraggableSides
              {...draggableProps}
              canDrag={!isLoading}
              parentItemType={draggableItemType}
              orderId={workOrder?.id}
              canDragStartSide={isRequestedOrPlanned}
              canDragStopSide={isNotFinished}
              onDrop={onPullEnd}
              height={`${orderStyles.height}px`}
              orderRegularWidth={width}
              orderExpandedWidth={orderStyles.width as number}
              // statusCustomisations={getVisualColorTheme(workOrder.derivedStatus)}
              inDetailedView={inDetailedView}
            />
          )}
          <div
            className={styles.tableEvent}
            style={{
              ...omit({ ...orderStyles }, ["top", "left", "height"]),
              cursor: isLoading
                ? "progress"
                : isRequestedOrPlanned && isOurItem
                  ? "grab"
                  : isOurItem
                    ? "pointer"
                    : "default",
              opacity: isOurItem ? 1 : 0.65,
              boxShadow: isDragging
                ? "inset 0px 0px 10px 0px rgba(0, 0, 0, 0.3)"
                : orderStyles.boxShadow,
              width: Math.max(orderStyles.width, 45),
              borderColor: width <= 0 ? "#DD877E" : orderStyles.borderColor,
            }}
            ref={drag}
          >
            <div style={{ ...orderContentWrapperStyles }}>
              {isLoading && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  style={{
                    zIndex: 999,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  },
);
export default memo(WorkOrderAllocation);
